import { useState } from 'react';

import useIdleTimer from '@/hooks/use-idle-timer';

import BaseQuestion from '@/components/guided-journal/base-question';
import { QuestionControllerProps } from '@/components/guided-journal/types';

interface GoalImportanceProps extends QuestionControllerProps {
  showInitialBubble?: boolean;
}

const GoalImportance = ({ questionId, showInitialBubble, onSubmit }: GoalImportanceProps) => {
  const [bubble, setBubble] = useState<string | undefined>(
    showInitialBubble ? 'Thanks for such a thoughful response. That was great!' : undefined
  );
  const { getIdleTime, getTotalTime } = useIdleTimer({
    onUpdate: (data) => {
      if (data.idleTime >= 90) {
        setBubble(
          'If it helps, think about the values behind the goal or why you want to achieve this goal.'
        );
      }
    },
  });

  return (
    <BaseQuestion
      question="Why do you think this goal is important to you?"
      type="text"
      bubble={bubble}
      onSubmit={(entry) => {
        onSubmit({
          ...entry,
          id: questionId,
          idle: getIdleTime(),
          time: getTotalTime(),
        });
      }}
    />
  );
};

export default GoalImportance;
