import { useState } from 'react';

import useIdleTimer from '@/hooks/use-idle-timer';

import BaseQuestion from '@/components/guided-journal/base-question';
import { QuestionControllerProps } from '@/components/guided-journal/types';

interface YourGoalProps extends QuestionControllerProps {
  doYouHaveGoalsAnswer: boolean;
}

const YourGoal = ({ questionId, onSubmit, doYouHaveGoalsAnswer }: YourGoalProps) => {
  const question = doYouHaveGoalsAnswer
    ? 'Do you have any goals for this semester or year? Could be school related or not - anything you hope happens!'
    : "If you could do anything for your career knowing that you'd be successful, make plenty of money, and people would approve, what would you do?";
  const type = 'short-text';
  const [bubble, setBubble] = useState<string>(
    doYouHaveGoalsAnswer
      ? 'Great! What is one of those goals or things you would do?'
      : "That's okay! What if you thought about it this way..."
  );
  const [skippable, setSkippable] = useState(false);
  const { getIdleTime, getTotalTime, getActiveTime } = useIdleTimer({
    onUpdate: (data) => {
      if (data.idleTime >= 60) {
        setBubble(
          'Stuck? Think about the goal that means the most to you... There are no wrong answers!'
        );
      }
    },
  });

  return (
    <BaseQuestion
      question={question}
      type={type}
      bubble={bubble}
      nextLabel={skippable ? 'Skip' : undefined}
      onSubmit={(entry) => {
        if (getActiveTime() < 90 && !skippable) {
          setBubble(
            "That's so interesting--do tell! Please type even more about your goal in the textbox"
          );
          setSkippable(true);
          return;
        }

        onSubmit({
          ...entry,
          id: questionId,
          idle: getIdleTime(),
          time: getTotalTime(),
        });
      }}
    />
  );
};

export default YourGoal;
