import { useState, useRef, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { useMount } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';

import PlusIcon from '@/assets/icons/plus.svg';

export interface NotificationProps {
  dismissible?: boolean;
  fixed?: boolean;
  type: 'success' | 'error' | 'warning';
  autoDismissible?: number;
  scrollTo?: boolean;
  cta?: React.ReactNode;
}

const isInViewport = function (bounding: DOMRect) {
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;

  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= windowHeight &&
    bounding.right <= windowWidth
  );
};

const NotificationInner: React.FC<NotificationProps & { close(): void }> = ({
  children,
  type,
  fixed,
  dismissible,
  close,
  scrollTo,
  cta,
}) => {
  const element = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (element.current && scrollTo) {
      const position = element.current.getBoundingClientRect();

      if (!isInViewport(position)) {
        window.scrollBy({
          behavior: 'smooth',
          top: position.top - 32,
        });
      }
    }
  }, []);

  return (
    <motion.div
      ref={element}
      className={classNames('c-notification', `c-notification--${type}`, {
        'c-notification--fixed': fixed,
      })}
      initial={{ opacity: 0, transform: fixed ? 'translateY(2rem) translateX(-50%)' : undefined }}
      animate={{ opacity: 1, transform: fixed ? 'translateY(0rem) translateX(-50%)' : undefined }}
      exit={{ opacity: 0, transform: fixed ? 'translateY(2rem) translateX(-50%)' : undefined }}
      transition={{ duration: 0.4 }}
    >
      <div className="c-notification__content">{children}</div>
      {typeof cta !== 'undefined' && (
        <div className={classNames('u-ml-auto', { 'u-mr-2': dismissible })}>{cta}</div>
      )}
      {dismissible && (
        <a
          href="#"
          className="c-notification__close"
          onClick={(event) => {
            event.preventDefault();

            close();
          }}
        >
          <PlusIcon />
        </a>
      )}
    </motion.div>
  );
};

export const Notification: React.FC<NotificationProps> = (props) => {
  const { autoDismissible } = props;
  const [isActive, setIsActive] = useState(false);

  useMount(() => {
    let timeout: NodeJS.Timeout | undefined;
    setIsActive(true);
    if (autoDismissible) {
      timeout = setTimeout(() => {
        setIsActive(false);
      }, autoDismissible);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  });

  return (
    <AnimatePresence>
      {isActive && (
        <NotificationInner
          {...props}
          close={() => {
            setIsActive(false);
          }}
        />
      )}
    </AnimatePresence>
  );
};

export default Notification;
