import { useState } from 'react';

import useIdleTimer from '@/hooks/use-idle-timer';

import BaseQuestion from '@/components/guided-journal/base-question';
import { QuestionControllerProps } from '@/components/guided-journal/types';

interface LifeGoalProps extends QuestionControllerProps {
  hideInitialBubble?: boolean;
}

const LifeGoal = ({ questionId, onSubmit, hideInitialBubble }: LifeGoalProps) => {
  const [bubble, setBubble] = useState<string | undefined>(
    !hideInitialBubble ? 'That sounds awesome! Thanks for sharing.' : undefined
  );
  const { getIdleTime, getTotalTime } = useIdleTimer({
    onUpdate: (data) => {
      if (data.idleTime >= 90) {
        setBubble(
          "Shortening your long term/life goal into one sentence isn't easy, but don't give up!"
        );
      }
    },
  });

  return (
    <BaseQuestion
      question="Alright! To sum this up...if you had to put your ultimate life goal in just one sentence, what would that sentence be?"
      type="text"
      bubble={bubble}
      onSubmit={(entry) => {
        onSubmit({
          ...entry,
          id: questionId,
          idle: getIdleTime(),
          time: getTotalTime(),
        });
      }}
    />
  );
};

export default LifeGoal;
