import useIdleTimer from '@/hooks/use-idle-timer';

import BaseQuestion from '@/components/guided-journal/base-question';
import { QuestionControllerProps } from '@/components/guided-journal/types';

interface ApproveStatementProps extends QuestionControllerProps {
  statement: string;
}

const ApproveStatement = ({ questionId, onSubmit, statement }: ApproveStatementProps) => {
  const { getIdleTime, getTotalTime } = useIdleTimer({
    events: ['keydown', 'mousemove', 'touchstart', 'touchmove', 'wheel'],
  });

  return (
    <BaseQuestion
      question={statement}
      type="yesno"
      bubble="Do you like this mission statement?"
      onSubmit={(entry) => {
        onSubmit({
          ...entry,
          id: questionId,
          idle: getIdleTime(),
          time: getTotalTime(),
        });
      }}
    />
  );
};

export default ApproveStatement;
