import { useState, KeyboardEvent, useRef } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { AnimatePresence, motion } from 'framer-motion';

import Button from '@/components/button';

import GuideCliffIllustration from '@/assets/illustrations/guide-cliff.svg';
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';

type QuestionType = 'yesno' | 'short-text' | 'text';

interface BaseQuestionProps {
  question: string;
  type: QuestionType;
  bubble?: string;
  nextLabel?: string;
  className?: string;
  onSubmit: (data: { answer: string | boolean; question: string; type: QuestionType }) => void;
}

const BaseQuestion: React.FC<BaseQuestionProps> = ({
  question,
  type,
  bubble,
  onSubmit,
  nextLabel = 'Next',
  className,
}) => {
  const router = useRouter();
  const [nextDisabled, setNextDisabled] = useState(true);
  const inputEl = useRef<HTMLInputElement>(null);
  const textareaEl = useRef<HTMLTextAreaElement>(null);

  const illustrationStyle = {
    top: router.asPath.startsWith('/guided-journal') ? undefined : '5rem',
  };

  const submit = (answer: string | boolean) => {
    onSubmit({ question, type, answer });
  };

  const handleEnter = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.metaKey || event.ctrlKey || event.altKey || event.shiftKey || event.keyCode !== 13) {
      return;
    }

    const value = event.currentTarget.value;
    if (!value || !value.length) {
      return;
    }

    submit(value);
  };

  const handleInput = () => {
    let value;
    if (textareaEl.current) {
      value = textareaEl.current.value;
    } else if (inputEl.current) {
      value = inputEl.current.value;
    }

    if (nextDisabled && value && value.length) {
      setNextDisabled(false);
    } else if (!nextDisabled && (!value || !value.length)) {
      setNextDisabled(true);
    }
  };

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{ duration: 0.5 }}
          key={question}
          className={className}
        >
          <div className="o-row u-justify-center">
            <div className="o-col-10@lg o-col-8@xl">
              <div className="c-card u-px-6 u-py-6">
                <div>
                  <h3 className="u-mb-4 u-text-center">{question}</h3>
                  {type === 'yesno' && (
                    <div className="u-text-center">
                      <Button
                        className="u-mx-1"
                        onClick={(event) => {
                          event.preventDefault();
                          submit(true);
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        className="u-mx-1"
                        onClick={(event) => {
                          event.preventDefault();
                          submit(false);
                        }}
                      >
                        No
                      </Button>
                    </div>
                  )}
                  {(type === 'short-text' || type === 'text') && (
                    <div>
                      <div className="c-form__element">
                        <div className="c-form__field">
                          {type === 'short-text' && (
                            <input
                              ref={inputEl}
                              type="text"
                              placeholder="Your thoughts..."
                              onKeyDown={handleEnter}
                              onInput={handleInput}
                            />
                          )}
                          {type === 'text' && (
                            <textarea
                              ref={textareaEl}
                              cols={1}
                              rows={1}
                              placeholder="Your thoughts..."
                              onKeyDown={handleEnter}
                              onInput={handleInput}
                            ></textarea>
                          )}
                        </div>
                      </div>
                      <div className="u-text-right">
                        <a
                          href="#"
                          className={classNames('u-text-xs', { 'is-disabled': nextDisabled })}
                          onClick={(event) => {
                            event.preventDefault();
                            let value;
                            if (textareaEl.current) {
                              value = textareaEl.current.value;
                            } else if (inputEl.current) {
                              value = inputEl.current.value;
                            }

                            if (!value || !value.length) {
                              return;
                            }

                            submit(value);
                          }}
                        >
                          {nextLabel}{' '}
                          <ArrowRightIcon className="o-svg-icon o-svg-fill u-ml-1 u-text-black" />
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
      <div className="c-illustration c-illustration--help" style={illustrationStyle}>
        <GuideCliffIllustration className="c-illustration__asset" />
        <AnimatePresence exitBeforeEnter>
          {typeof bubble !== 'undefined' && (
            <motion.div
              key={bubble}
              initial={{ opacity: 0, x: -16, y: 16, scale: 0.95 }}
              animate={{ opacity: 1, x: 0, y: 0, scale: 1, transition: { delay: 0.2 } }}
              exit={{ opacity: 0, x: 16, y: -16, scale: 0.95, transition: { delay: 0 } }}
              transition={{ duration: 0.5 }}
              className="c-illustration__text-bubble"
            >
              <p>{bubble}</p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default BaseQuestion;
