import { useState } from 'react';

import useIdleTimer from '@/hooks/use-idle-timer';

import BaseQuestion from '@/components/guided-journal/base-question';
import { QuestionControllerProps } from '@/components/guided-journal/types';

interface LongTermGoalConnectionProps extends QuestionControllerProps {
  doesItConnectToLongTermGoal: boolean;
  isPersonalGoal?: boolean;
}

const LongTermGoalConnection = ({
  questionId,
  onSubmit,
  doesItConnectToLongTermGoal,
  isPersonalGoal,
}: LongTermGoalConnectionProps) => {
  let question = 'Does this goal connect to any other long-term goals or dreams you have?';
  const bubbleMsg =
    "We often have many short term goals. What about picking the one most related to career (OR) the one that's most important to you? Then think about how this connects to a long term goal.";

  let initalBubbleMsg = 'Great! How do these goal connect or relate to each other?';
  let showIdleBubbleMsg = true;
  let className: string | undefined = 'u-mt-8';

  if (!doesItConnectToLongTermGoal) {
    question = bubbleMsg;
    showIdleBubbleMsg = false;

    if (isPersonalGoal) {
      initalBubbleMsg =
        'I get it! Try thinking of it this way--choose a major goal, like a career goal, and then reflect on how it ties in with big picture life aspirations.';
      className = 'u-mt-6';
    } else {
      initalBubbleMsg =
        "That's okay! Making sense of how goals connect is no easy task, but we are here to help!";
      className = undefined;
    }
  }

  const [bubble, setBubble] = useState<string>(initalBubbleMsg);
  const { getIdleTime, getTotalTime } = useIdleTimer({
    onUpdate: showIdleBubbleMsg
      ? (data) => {
          if (data.idleTime >= 90) {
            setBubble(bubbleMsg);
          }
        }
      : undefined,
  });

  return (
    <BaseQuestion
      question={question}
      type="short-text"
      bubble={bubble}
      className={className}
      onSubmit={(entry) => {
        onSubmit({
          ...entry,
          id: questionId,
          idle: getIdleTime(),
          time: getTotalTime(),
        });
      }}
    />
  );
};

export default LongTermGoalConnection;
