import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { useStore } from '@/store';
import { ITrueNorthStatementEntry } from '@/store/stores/truenorth';

import FetchSuspense from '@/components/fetch-suspense';
import Notification from '@/components/notification';
import Button from '@/components/button';

import { JournalQuestions } from './types';
import DoYouHaveGoal from './questions/0-do-you-have-goal';
import YourGoal from './questions/1-your-goal';
import GoalImportance from './questions/2-goal-importance';
import DoesItConnectToLongTermGoal from './questions/3-does-it-connect-to-long-term-goal';
import IsPersonalGoal from './questions/4-is-personal-goal';
import LongTermGoalConnection from './questions/5-long-term-goal-connection';
import SuccessfulFuture from './questions/6-successful-future';
import LifeGoal from './questions/7-life-goal';
import ApproveStatement from './questions/8-approve-statement';

const GuidedJournalCurrentQuestion = observer(() => {
  const store = useStore();
  const router = useRouter();

  const onSubmit = useCallback((entry: ITrueNorthStatementEntry) => {
    let promise = Promise.resolve(true);

    if (!router.asPath.startsWith('/guided-journal')) {
      promise = router.push('/guided-journal');
    }

    promise
      .then(() => store.truenorth.addEditableEntry(entry))
      .then(() => {
        if (entry.id === JournalQuestions.LifeGoal) {
          store.truenorth.setTrueNorthStatement(String(entry.answer));
        }
      })
      .then(async () => {
        if (entry.id === JournalQuestions.ApproveStatement && entry.answer === true) {
          store.truenorth.completeEditable();
          await store.truenorth.saveEditable();
          router.push('/');
        }

        return Promise.resolve();
      });
  }, []);

  const doYouHaveGoalEntry = store.truenorth.editableGetQuestionFirstEntry(
    JournalQuestions.DoYouHaveGoal
  );
  if (!doYouHaveGoalEntry) {
    return <DoYouHaveGoal questionId={JournalQuestions.DoYouHaveGoal} onSubmit={onSubmit} />;
  }

  const yourGoalEntry = store.truenorth.editableGetQuestionFirstEntry(JournalQuestions.YourGoal);
  if (!yourGoalEntry) {
    return (
      <YourGoal
        questionId={JournalQuestions.YourGoal}
        onSubmit={onSubmit}
        doYouHaveGoalsAnswer={Boolean(doYouHaveGoalEntry.answer)}
      />
    );
  }

  if (!store.truenorth.editableHasQuestionEntry(JournalQuestions.GoalImportance)) {
    return (
      <GoalImportance
        questionId={JournalQuestions.GoalImportance}
        showInitialBubble={yourGoalEntry.time - yourGoalEntry.idle >= 90}
        onSubmit={onSubmit}
      />
    );
  }

  const DoesItConnectToLongTermGoalEntry = store.truenorth.editableGetQuestionFirstEntry(
    JournalQuestions.DoesItConnectToLongTermGoal
  );
  if (!DoesItConnectToLongTermGoalEntry) {
    return (
      <DoesItConnectToLongTermGoal
        questionId={JournalQuestions.DoesItConnectToLongTermGoal}
        onSubmit={onSubmit}
      />
    );
  }

  const IsPersonalGoalEntry = store.truenorth.editableGetQuestionFirstEntry(
    JournalQuestions.IsPersonalGoal
  );
  if (DoesItConnectToLongTermGoalEntry.answer === false && !IsPersonalGoalEntry) {
    return <IsPersonalGoal questionId={JournalQuestions.IsPersonalGoal} onSubmit={onSubmit} />;
  }

  if (!store.truenorth.editableHasQuestionEntry(JournalQuestions.LongTermGoalConnection)) {
    return (
      <LongTermGoalConnection
        questionId={JournalQuestions.LongTermGoalConnection}
        onSubmit={onSubmit}
        doesItConnectToLongTermGoal={Boolean(DoesItConnectToLongTermGoalEntry.answer)}
        isPersonalGoal={IsPersonalGoalEntry ? Boolean(IsPersonalGoalEntry.answer) : undefined}
      />
    );
  }

  if (!store.truenorth.editableHasQuestionEntry(JournalQuestions.SuccessfulFuture)) {
    return <SuccessfulFuture questionId={JournalQuestions.SuccessfulFuture} onSubmit={onSubmit} />;
  }

  const LifeGoalEntry = store.truenorth.editableGetQuestionLastEntry(JournalQuestions.LifeGoal);
  const ApproveStatementEntry = store.truenorth.editableGetQuestionLastEntry(
    JournalQuestions.ApproveStatement
  );
  const LifeGoalEntryIndex = store.truenorth.editableGetEntryIndex(LifeGoalEntry);
  const ApproveStatementEntryIndex = store.truenorth.editableGetEntryIndex(ApproveStatementEntry);
  if (
    !LifeGoalEntry ||
    (ApproveStatementEntry &&
      ApproveStatementEntry.answer === false &&
      ApproveStatementEntryIndex > LifeGoalEntryIndex)
  ) {
    return (
      <LifeGoal
        questionId={JournalQuestions.LifeGoal}
        onSubmit={onSubmit}
        hideInitialBubble={Boolean(ApproveStatementEntry)}
      />
    );
  }

  return (
    <ApproveStatement
      questionId={JournalQuestions.ApproveStatement}
      onSubmit={onSubmit}
      statement={String(LifeGoalEntry.answer)}
    />
  );
});

const GuidedJournalErrorNotification = observer(() => {
  const store = useStore();

  if (store.truenorth.editableSaveState.isRejected) {
    const fieldErrors = store.truenorth.editableSaveState.getFieldErrors([]);
    const errors = fieldErrors.__other;

    return (
      <Notification
        fixed
        type="error"
        cta={
          <Button
            buttonType="notification"
            onClick={(event) => {
              event.preventDefault();
              store.truenorth.saveEditable();
            }}
          >
            Try again
          </Button>
        }
      >
        <p>Something went wrong while saving your true north statement.</p>
        {errors.length && (
          <ul className="u-my-1">
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        )}
      </Notification>
    );
  }

  return null;
});

const CurrentQuestion = observer(() => {
  const store = useStore();

  return (
    <FetchSuspense state={store.truenorth.editableFetchState}>
      <GuidedJournalCurrentQuestion />
      <GuidedJournalErrorNotification />
    </FetchSuspense>
  );
});

export default CurrentQuestion;
