import { useEffect, useRef } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';

const msToSeconds = (value: number) => Math.floor(value / 1000);

interface UseIdleTimerProps {
  events?: Array<keyof DocumentEventMap>;
  onUpdate?: (data: { isActive: boolean; activeTime: number; idleTime: number }) => void;
  onStartTyping?: () => void;
}

export const useIdleTimer = ({
  events = ['keydown'],
  onUpdate,
  onStartTyping,
}: UseIdleTimerProps = {}) => {
  const isActive = useRef(false);
  const activeTimer = useRef(0);
  const idleTimer = useRef(0);
  const lastTime = useRef(Date.now());

  useIsomorphicLayoutEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    const handleEvent = () => {
      isActive.current = true;

      if (onStartTyping) {
        onStartTyping();
      }

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        isActive.current = false;
        timeoutId = undefined;
      }, 200);
    };

    events.forEach((event) => {
      document.addEventListener(event, handleEvent, { passive: true });
    });

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, handleEvent);
      });

      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  const update = (emitUpdate: boolean = true) => {
    const time = Date.now();
    const delta = time - lastTime.current;

    if (isActive.current) {
      activeTimer.current += delta;
    } else {
      idleTimer.current += delta;
    }

    lastTime.current = time;

    if (emitUpdate && onUpdate) {
      onUpdate({
        isActive: isActive.current,
        activeTime: msToSeconds(activeTimer.current),
        idleTime: msToSeconds(idleTimer.current),
      });
    }
  };

  useEffect(() => {
    const intervalId = setInterval(update, 100);

    update();

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const reset = () => {
    isActive.current = false;
    activeTimer.current = 0;
    idleTimer.current = 0;
    lastTime.current = Date.now();
  };

  return {
    isActive: () => isActive.current,
    getActiveTime: () => msToSeconds(activeTimer.current),
    getIdleTime: () => msToSeconds(idleTimer.current),
    getTotalTime: () => msToSeconds(activeTimer.current) + msToSeconds(idleTimer.current),
    reset: () => reset(),
    forceUpdate: () => update(false),
  };
};

export default useIdleTimer;
