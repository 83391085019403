import { useState } from 'react';

import useIdleTimer from '@/hooks/use-idle-timer';

import BaseQuestion from '@/components/guided-journal/base-question';
import { QuestionControllerProps } from '@/components/guided-journal/types';

const DoYouHaveGoal = ({ questionId, onSubmit }: QuestionControllerProps) => {
  const [bubble, setBubble] = useState<string>();
  const { getIdleTime, getTotalTime } = useIdleTimer({
    onUpdate: (data) => {
      if (data.idleTime >= 60) {
        setBubble('Stuck? Remember there are no wrong answers!');
      }
    },
    events: ['keydown', 'mousemove', 'touchstart', 'touchmove', 'wheel'],
  });

  return (
    <BaseQuestion
      question="Do you have any goals for this semester or year? Could be school related or not - anything you hope happens!"
      type="yesno"
      bubble={bubble}
      onSubmit={(entry) => {
        onSubmit({
          ...entry,
          id: questionId,
          idle: getIdleTime(),
          time: getTotalTime(),
        });
      }}
    />
  );
};

export default DoYouHaveGoal;
