import { useState } from 'react';

import useIdleTimer from '@/hooks/use-idle-timer';

import BaseQuestion from '@/components/guided-journal/base-question';
import { QuestionControllerProps } from '@/components/guided-journal/types';

const SuccessfulFuture = ({ questionId, onSubmit }: QuestionControllerProps) => {
  const [bubble, setBubble] = useState<string>("That's awesome! Thanks for sharing that.");
  const { getIdleTime, getTotalTime } = useIdleTimer({
    onUpdate: (data) => {
      if (data.idleTime >= 90) {
        setBubble(
          "If that's too broad, think about what a successful future would look like in 20-30 years from now."
        );
      }
    },
  });

  return (
    <BaseQuestion
      question="Assuming you achieve this goal, where does this lead you? In other words, what does ultimate success look like for you in the future?"
      type="text"
      bubble={bubble}
      onSubmit={(entry) => {
        onSubmit({
          ...entry,
          id: questionId,
          idle: getIdleTime(),
          time: getTotalTime(),
        });
      }}
    />
  );
};

export default SuccessfulFuture;
