import useIdleTimer from '@/hooks/use-idle-timer';

import BaseQuestion from '@/components/guided-journal/base-question';
import { QuestionControllerProps } from '@/components/guided-journal/types';

const DoesItConnectToLongTermGoal = ({ questionId, onSubmit }: QuestionControllerProps) => {
  const { getIdleTime, getTotalTime } = useIdleTimer({
    events: ['keydown', 'mousemove', 'touchstart', 'touchmove', 'wheel'],
  });

  return (
    <BaseQuestion
      question="Does this goal connect to any other long-term goals or dreams you have?"
      type="yesno"
      bubble="For sure, that makes a lot of sense!"
      onSubmit={(entry) => {
        onSubmit({
          ...entry,
          id: questionId,
          idle: getIdleTime(),
          time: getTotalTime(),
        });
      }}
    />
  );
};

export default DoesItConnectToLongTermGoal;
