import { ITrueNorthStatementEntry } from '@/store/stores/truenorth';

export interface QuestionControllerProps {
  questionId: number;
  onSubmit: (entry: ITrueNorthStatementEntry) => void;
}

export enum JournalQuestions {
  DoYouHaveGoal,
  YourGoal,
  GoalImportance,
  DoesItConnectToLongTermGoal,
  IsPersonalGoal,
  LongTermGoalConnection,
  SuccessfulFuture,
  LifeGoal,
  ApproveStatement,
}
